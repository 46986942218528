import React from 'react'
import PropTypes from 'prop-types'
import '../scss/pricing-banner.scss'
import RenderGatsByImage from '../common/RenderGatsByImage'
import CTAButton from '../common/CTAButton'

export default function PricingBanner(props) {
  const { pricingBanner } = props

  return (
    <React.Fragment>
      <div className="pb-container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5 d-flex align-items-center">
              <div className="d-flex align-item-center styled-text">
                {pricingBanner.title.map((item, index) => {
                  return (
                    <div className={item.cssClass} key={item.text}>
                      {item.text}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-12 col-md-7 d-flex align-items-center">
              <div className="wc-description">
                <div className="mb-4">{pricingBanner.description}</div>
              </div>
            </div>
          </div>
          <div className="row card-section">
            {pricingBanner.plans.map((item, index) => {
              return (
                <div className="col-12 col-md-4 pb-card" key={item.title}>
                  <div>
                    <div className="d-flex heading align-items-center justify-content-between">
                      <div className="name">{item.title}</div>
                      <div className="name">{item.price}</div>

                      {item.type && <div className="type">{item.type}</div>}
                    </div>
                    <div className="body">
                      {item.question && (
                        <div className="question">{item.question}</div>
                      )}
                      {item.notes.map((note, index) => {
                        return (
                          <div className="note mb-3 d-flex" key={note.title}>
                            <div className=" note-icon">
                              <RenderGatsByImage
                                imageSource={note.noteIcon}
                                alt={note.text}
                                width={14}
                                height={14}
                              />
                            </div>
                            <div className="note-desc">{note.note}</div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="footer align-items-center d-flex justify-content-center">
                      <CTAButton
                        text={item.ctaDetails.text}
                        link={item.ctaDetails.link}
                        type={item.ctaDetails.type}
                        rightHoverIcon={
                          index === 1
                            ? '/img/koble/arrow_purple_plan_icon.png'
                            : '/img/koble/arrow_black_icon.svg'
                        }
                        bgColor={item.ctaDetails.bgColor}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </React.Fragment>
  )
}

PricingBanner.propTypes = {
  PricingBanner: PropTypes.object
}
