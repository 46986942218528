import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PricingBanner from '../components/koble/PricingBanner'
import KobleRightChoice from '../components/koble/KobleRightChoice'

// eslint-disable-next-line
export const PricingPageTemplate = ({ kobleRightChoice, pricingBanner }) => {
  return (
    <div>
      <PricingBanner pricingBanner={pricingBanner} />
      <KobleRightChoice {...kobleRightChoice} />
    </div>
  )
}

PricingPageTemplate.propTypes = {
  pricingBanner: PropTypes.object,
  kobleRightChoice: PropTypes.object
}

const PricingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <PricingPageTemplate
        pricingBanner={frontmatter.pricingBanner}
        kobleRightChoice={frontmatter.kobleRightChoice}
      />
    </Layout>
  )
}

PricingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default PricingPage

export const PricingPageQuery = graphql`
  query PricingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        kobleRightChoice {
          title {
            text
            cssClass
          }
          description
          ctaDetails {
            text
            bgColor
            link
            type
          }
          video {
            extension
            publicURL

            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        pricingBanner {
          title {
            cssClass
            text
          }
          description
          plans {
            title
            type
            price
            background
            question
            planIcon {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
            ctaDetails {
              text
              bgColor
              link
              type
            }
            notes {
              note
              noteIcon {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
