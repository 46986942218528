import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

export default function RenderGatsByImage(props) {
  const {
    height = 770,
    width = 'auto',
    imgPosition = 'top left',
    imageSource,
    alt = 'alternate text'
  } = props

  let heroImage = getImage(imageSource)

  if (!heroImage) {
    if (imageSource && imageSource.publicURL) {
      heroImage = { url: imageSource?.publicURL }
    } else {
      heroImage = imageSource
    }
  }
  return (
    <div style={{ maxWidth: width, flexGrow: 1 }}>
      {heroImage?.url ? (
        <img
          src={heroImage.url}
          objectFit={'cover'}
          objectPosition={imgPosition}
          style={{
            gridArea: '1/1',
            // You can set a maximum height for the image, if you wish.
            height: height,
            width: '100%'
          }}
          // You can optionally force an aspect ratio for the generated image
          aspectratio={3 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt={alt}
          formats={['auto', 'webp', 'avif']}
        />
      ) : (
        <GatsbyImage
          image={heroImage}
          objectFit={'cover'}
          objectPosition={imgPosition}
          style={{
            gridArea: '1/1',
            // You can set a maximum height for the image, if you wish.
            maxHeight: height
          }}
          layout="fullWidth"
          // You can optionally force an aspect ratio for the generated image
          aspectratio={3 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt={alt}
          formats={['auto', 'webp', 'avif']}
        />
      )}
    </div>
  )
}

RenderGatsByImage.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alt: PropTypes.string,
  imgPosition: PropTypes.string,
  imageSource: PropTypes.object
}
